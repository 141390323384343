import { useDispatch } from 'react-redux';

import { auth as types } from '@codesass/types';

import { createUser } from 'modules/auth/slice';

import Form from './Form';
const Register = () => {
  const dispatch = useDispatch();

  const register = async (auth: types.UserCredential) => {
    dispatch(createUser(auth));
  };

  return (
    <Form
      variant="register"
      title="ลงทะเบียน"
      altTitle="มีบัญชีสมาชิกอยู่แล้ว?"
      altText="เข้าสู่ระบบ"
      altLink="/auth/login"
      onSubmit={register}
    ></Form>
  );
};

export default Register;
