import Register from 'modules/auth/components/Register';
import PreventLoggedInGuard from 'modules/auth/components/PreventLoggedInGuard';

const RegisterPage = () => {
  return (
    <PreventLoggedInGuard>
      <Register></Register>
    </PreventLoggedInGuard>
  );
};

export default RegisterPage;
